<template>
  <div class="tree-mobile">
    <div class="container catalog-header">
      <h1>{{ getCategoryLocaleData(rootCategory).name }}</h1>

      <a href="#" @click.prevent="closeCat">
        <i class="svg-icon cross"></i>
      </a>
      <!--      <nuxt-link v-if="!isAllProdsPath" :to="localePath('/catalog/')" @click.native="closeCat">{{ $t('all_products') }}</nuxt-link>-->
      <!--      <span v-else @click="closeCat">{{ $t('all_products') }}</span>-->
    </div>

    <div class="tree-items">

      <div class="container search-file">
        <products-search-input @search-input="closeCat"/>
      </div>

      <div class="catalog-square">
        <template v-for="filter in filters">
          <div class="catalog-square-item" v-show="!showFilterChild" @click="getCategoriesFilter(filter.slug);
          showFilterChild = true">
            <div class="catalog-square-item__container">
              <span class="cat-square-item__header">{{
                  getFilterNameCatalog(filter)
                }}</span>
              <i class="svg-icon arrow-mobile"></i>
            </div>
          </div>
        </template>
      </div>

      <div class="catalog-square" v-show="activeFilter && showFilterChild">

        <div class="name-filter" @click="$emit('set-active-filter', filters[0].slug); showFilterChild = false">
          <i class="svg-icon arrow-mobile"></i>
          <span>{{ getFilterNameCatalogBySlug() }}</span>
        </div>

        <div v-for="sub1 in rootCategory.subcategories">
              <div class="catalog-square-item" @click="action = sub1.id; showChild = true;">


                <div class="catalog-square-item__container">
                <span class="cat-square-item__header"
                      :class="{'active': action === sub1.id }">{{
                    getCategoryLocaleDataSeoFilter(sub1, activeFilter)
                  }}</span>
                  <i class="svg-icon arrow-mobile"></i>
                </div>
              </div>
        </div>

        <div class="makers" v-if="landingFolder[0] && Object.keys(landingFolder[0]).length">
          <div class="maker" v-for="maker in landingFolder[0].makers">
            <nuxt-link @click.native="closeCat"  class="button"
                       :to="localePath(`/${landingFolder[0].slug}/${maker.slug}`)">{{
                getMakerTitleButton(maker)
              }}
            </nuxt-link>
            </div>
        </div>

        <nuxt-link class="favourites" v-show="favouriteProductsExists" :to="localePath('/products/favourites/')"
                   :aria-label="$t('favourites')">
          <i class="svg-icon star icon-brand"></i><span @click="closeCat">Favorites</span>
        </nuxt-link>
      </div>

      <div class="catalog-rendered-items">
        <template v-for="sub1 in rootCategory.subcategories">
          <transition :name="slideTransition">
            <div class="rendered_value" v-show="action === sub1.id" :class="{'hidden': action !== sub1.id}">
              <div class="name-category" @click="action = null; showChild = false">
                <i class="svg-icon arrow-mobile"></i>
                <span>{{ getCategoryLocaleDataSeoFilter(sub1, activeFilter) }}</span>
              </div>
              <div class="items-all">
                <nuxt-link :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${activeFilter}`)"
                           @click.native="closeCat">{{$t('all_products') }}</nuxt-link>
                <nuxt-link :to="localePath(`/${rootCategory.slug}/${sub1.slug}/overview`)" @click.native="closeCat">{{ $t('category_view')
                  }}</nuxt-link>
              </div>
              <div class="pre-items">
                <div class="items">
                  <template v-for="sub2 in sub1.subcategories">

                    <nuxt-link :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${sub2.slug}/${activeFilter}`)">
                        <span class="catalog-square-item__link" @click="closeCat">{{
                            getCategoryLocaleDataSeoFilter(sub2, activeFilter)
                          }}</span>
                    </nuxt-link>

                  </template>
                </div>
              </div>
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsSearchInput from "@/components/catalog/products-search-input.vue";
import {Category} from "@/models/category";
import {LandingFolder} from "@/models/landing-folder";

export default {
  props: ['categories', 'filters', 'landingFolder', 'seoHeaders', 'activeFilter'],
  components: {ProductsSearchInput},
  data() {
    return {
      action: null,
      slideTransition: null,
      showFilterChild: false,
    }
  },
  watch: {
    showChild(value) {
      if (value) {
        this.setSlideTransition('slide-right');
      } else {
        this.setSlideTransition('slide-left');
      }
    },
  },
  computed: {
    rootCategory() {
      return this.categories[0];
    },
    isAllProdsPath() {
      return this.$route.path === this.localePath('/catalog/');
    },
    favouriteProductsExists() {
      return this.$auth.loggedIn && this.$store.getters.favouriteProductsExists;
    },
    getLandingFolderName() {
      return this.landingFolder[0].page_name_catalog_translations?.find(x => x.language.slug === this.$i18n.locale).title || {};
    },
  },
  methods: {
    getCategoryLocaleData(category) {
      return category.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    closeCat() {
      this.$emit('toggle-cat');
    },
    getCategoryLocaleDataSeoFilter(category, filter_slug) {
      const seoHeader = this.seoHeaders?.find(x => x.category.id === category.id && x.filter.slug === filter_slug) ||
          {};
      if (Object.keys(seoHeader).length) {
        return seoHeader.header_translations?.find(x => x.language.slug === this.$i18n.locale).header || {};
      } else {
        return category.category_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
      }
    },
    async getCategoriesFilter(slug) {
      this.$emit('set-active-filter', slug);
    },
    getFilterNameCatalog(filter) {
      return filter.name_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
    },
    getFilterNameCatalogBySlug() {
      if (this.activeFilter) {
        let filter = this.filters?.find(x => x.slug === this.activeFilter);
        return filter.name_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
      }
    },
    setSlideTransition(slideDirection) {
      setTimeout(() => {
        this.slideTransition = slideDirection;
      }, 500);
    },
    getMakerTitleButton(maker) {
      return maker.button_translations?.find(x => x.language.slug === this.$i18n.locale).title || {};
    },
  }
}

</script>

<i18n lang="yaml">
ru:
  all: 'Все'
  category_view: 'Обзор'
en:
  all: 'All'
  category_view: 'Overview'
</i18n>
